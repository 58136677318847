export const categories = [
  {
    title: 'Test1',
    icon: {
      src: 'https://assets.maccarianagency.com/the-front/illustrations/icon-headphone.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/illustrations/icon-headphone@2x.png 2x',
    },
  },
  {
    title: 'Test2',
    icon: {
      src: 'https://assets.maccarianagency.com/the-front/illustrations/icon-music.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/illustrations/icon-music@2x.png 2x',
    },
  },
  {
    title: 'Test3',
    icon: {
      src: 'https://assets.maccarianagency.com/the-front/illustrations/icon-laptop.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/illustrations/icon-laptop@2x.png 2x',
    },
  },
  {
    title: 'Test4',
    icon: {
      src: 'https://assets.maccarianagency.com/the-front/illustrations/icon-camera.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/illustrations/icon-camera@2x.png 2x',
    },
  },
  {
    title: 'Test5',
    icon: {
      src: 'https://assets.maccarianagency.com/the-front/illustrations/icon-game.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/illustrations/icon-game@2x.png 2x',
    },
  },
  {
    title: 'Test6',
    icon: {
      src: 'https://assets.maccarianagency.com/the-front/illustrations/icon-camera.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/illustrations/icon-camera@2x.png 2x',
    },
  },
];

export const featuredProducts = [
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product1.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product1@2x.png 2x',
    },
    price: 'RM 478',
    title: 'Rose Crassna',
    rating: 5,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product2.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product2@2x.png 2x',
    },
    price: 'RM 328',
    title: 'Loco Crassna',
    rating: 4,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product3.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product3@2x.png 2x',
    },
    price: 'RM 238',
    title: 'Saffron Crassna',
    rating: 5,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product4.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product4@2x.png 2x',
    },
    price: 'RM 228',
    title: 'Spicy Wood Crassna',
    rating: 3,
  },
];

export const mostSoldProducts = [
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product7.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product7@2x.png 2x',
    },
    title: 'Laptops and computers',
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product8.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product8@2x.png 2x',
    },
    title: 'Phones and tablets',
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product9.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product9@2x.png 2x',
    },
    title: 'Headphones and airpods',
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product8.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product8@2x.png 2x',
    },
    title: 'iWatch and smartwatch',
  },
];

export const news = [
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/los-angeles.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/rental/los-angeles@2x.jpg 2x',
    },
    title: 'The products you need',
    subtitle:
      "The Nike Zoom SB Stefan Janoski SE Men's Skateboarding Shoe is made with smooth suede and Nubuck…",
    cols: 2,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/san-francisco.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/rental/san-francisco@2x.jpg 2x',
    },
    title: 'The most viewed products of the month',
    subtitle:
      "The Nike Zoom SB Stefan Janoski SE Men's Skateboarding Shoe is made with smooth suede and Nubuck…",
    cols: 1,
    rows: 2,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/seattle.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/rental/seattle@2x.jpg 2x',
    },
    title: 'Why you need to have these products?',
    subtitle:
      "The Nike Zoom SB Stefan Janoski SE Men's Skateboarding Shoe is made with smooth suede and Nubuck…",
    cols: 3,
  },
];

export const latestProducts = [
    {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product1.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product1@2x.png 2x',
    },
    price: 'RM 478',
    title: 'Rose Crassna',
    rating: 5,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product2.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product2@2x.png 2x',
    },
    price: 'RM 328',
    title: 'Loco Crassna',
    rating: 4,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product3.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product3@2x.png 2x',
    },
    price: 'RM 238',
    title: 'Saffron Crassna',
    rating: 5,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product4.png',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/ecommerce/product4@2x.png 2x',
    },
    price: 'RM 228',
    title: 'Spicy Wood Crassna',
    rating: 3,
  },
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];
